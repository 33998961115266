import React from 'react';
import Typography from '@material-ui/core/Typography';
import { privateRedirect } from 'utils/loginService';

export default function Index() {
  privateRedirect('/qearth');
  
  return (
    <div style={{padding: '20px'}}>
    <Typography variant="h4" component="h1" gutterBottom>
      Redirecting
    </Typography>
    </div>
  );
}
