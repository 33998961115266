import { useEffect } from 'react';
import { useRouter } from 'next/router';

export const loginPath = '/login';

export const isLoggedIn = () => {
  let token = false;
  // necessary check to work with nextjs
  if (typeof window !== 'undefined') {
    token = window.localStorage.getItem('tokenId');
  }
  return Boolean(token);
};

export const privateRedirect = (route) => {
  const loggedIn = isLoggedIn();
  const router = useRouter();
  const redirectTo = loggedIn ? route : loginPath;

  useEffect(() => {
    router.push(redirectTo);
  }, []);
};

export const showLoginIfNeeded = (override) => {
  const loggedIn = isLoggedIn();
  const router = useRouter();

  useEffect(() => {
    if (!loggedIn && !!override) {
      router.push(loginPath);
    }
  }, [loggedIn, override]);
};

export const logout = (router) => {
  router.push('/login');

  window.localStorage.removeItem('tokenId');
  window.localStorage.removeItem('tokenRefresh');
  window.localStorage.removeItem('tokenAccess');
  window.localStorage.removeItem('userDetails');
};
